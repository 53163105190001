import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';

export default class TextTableRow extends React.PureComponent {
  render() {
    return (
      <TableRow>
        <TableCell className={this.props.className} align='right' component='th' scope='row'>{this.props.label}</TableCell>
        <TableCell className={this.props.className} align='right'>
          {this.props.value}
        </TableCell>
      </TableRow>
    )
  }
}
