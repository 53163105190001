import { STATES_ADDED} from './statesAddedAction';

const defaultState = [{}];
const statesAddedReducer = (state = defaultState, action) => {
  if (action.type === STATES_ADDED) {
    return action.payload.data;
  }
  return state;
}

export default statesAddedReducer;