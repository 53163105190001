import { Fragment } from "react";
import { List, TextField, BooleanField, NumberField, EditButton } from 'react-admin'
import { Route, Switch } from 'react-router';
import { Dialog, DialogTitle } from '@material-ui/core';

import Datagrid from '../common/Datagrid'
import Filter from './filter'
import Pagination from '../common/Pagination'
import FormCreate from './formCreate';
import FormEdit from './formEdit';
import UpdateActiveCheckbox from './updateActiveCheckbox';
import { makeStyles } from '@material-ui/core/styles';

const styles = {
  tableHeader: {
    textAlign: 'left'
  }
}

const useStyles = makeStyles(styles);

const FormList = (props) => {
  const classes = useStyles();

  const handleClose = () => {
  };

  return (
    <Fragment>
      <List {...props} filters={<Filter />} empty={false} exporter={false} pagination={<Pagination />} actions={false} bulkActionButtons={false}>
        <Datagrid displayHeadersWhenEmpty={true}>
          {props.permissions?.includes('Forms.Update') ? <EditButton label=""/> : null}
          <TextField source="state" label="States" headerClassName={classes?.tableHeader} />
          <TextField source="formNum" label="Form #" headerClassName={classes?.tableHeader} />
          <TextField source="financeCo" label="Finance Company" headerClassName={classes?.tableHeader} />
          <TextField source="submissionId" label="Sub. ID" headerClassName={classes?.tableHeader} />
          <NumberField source="frpDays" label="FRP (Days)" headerClassName={classes?.tableHeader} />
          <TextField source="duringFrp.cancelFeeCalc" label="FRP Cancel Fee Calc. Method" headerClassName={classes?.tableHeader} />
          <NumberField source="duringFrp.cancelFeeAmt" label="FRP Cancel Fee" headerClassName={classes?.tableHeader} options={{ style: 'currency', currency: 'USD' }} />
          <BooleanField source="duringFrp.finChargeRefund" label="FRP Fin. Charge Refund?" headerClassName={classes?.tableHeader} />
          <TextField source="afterFrp.refundCalc" label="Post FRP Refund Calc. Method" headerClassName={classes?.tableHeader} />
          <TextField source="afterFrp.cancelFeeCalc" label="Post FRP Cancel Fee Calc. Method" headerClassName={classes?.tableHeader} />
          <NumberField source="afterFrp.cancelFeeAmt" label="Post FRP Cancel Fee" headerClassName={classes?.tableHeader} options={{ style: 'currency', currency: 'USD' }} />
          <BooleanField source="afterFrp.finChargeRefund" label="Post FRP Fin. Charge Refund?" headerClassName={classes?.tableHeader} />
          {props.permissions?.includes('Forms.Update') ? <UpdateActiveCheckbox source="active" headerClassName={classes?.tableHeader} /> : <BooleanField source="active" headerClassName={classes?.tableHeader} />}
        </Datagrid>
      </List>
      <Switch>
        <Route path="/forms/create">
          <Dialog open={true} onClose={handleClose} maxWidth={false}>
            <DialogTitle onClose={handleClose}>
              Add States
            </DialogTitle>
            <FormCreate onCancel={handleClose} {...props} />
          </Dialog>
        </Route>
        <Route path="/forms/:id">
          {({ match }) => (
            <Dialog open={props.permissions?.includes('Forms.Update')} onClose={handleClose} maxWidth={false}>
              <DialogTitle onClose={handleClose}>
                Edit State
              </DialogTitle>
              <FormEdit id={match.params.id} onCancel={handleClose} {...props} />
            </Dialog>
            )}
          </Route>
      </Switch>
    </Fragment>
  );
}

export default FormList;
