import HistoryIcon from '@material-ui/icons/History';
import CalculationHistoryList from './calculationHistory';

const calculationHistory = {
  list: CalculationHistoryList,
  icon: HistoryIcon,
  options: { label: 'Calculation History' }
};

export default calculationHistory;
