import React from 'react';
import { Admin, Resource } from 'react-admin';
import { Provider } from 'react-redux';
import { Redirect } from 'react-router';
import { createHashHistory } from 'history';

import { form, search_forms_projections_formNumbers } from '../components/formManagement';
import calculationHistory from '../components/calculationHistory'
import userManagement from '../components/userManagement'
import { Layout } from '../layout';
import adminStore from './adminStore';
import authProvider from '../auth/authProvider'
import { Footer } from '../components/footer';
import dataProvider from '../dataProvider/dataProvider';
import resources from '../dataProvider/resources';
import theme from './theme';
import customRoutes from './customRoutes';

import statesAddedReducer from '../components/formManagement/statesAddedReducer';

const history = createHashHistory();
const RedirectCalculator = (props) => (
  <Redirect to="/calculator" />
);

const App = props => {
  //console.log("props", props);
  const account = props.account;

  return (
    <Provider
      store={adminStore({
        authProvider,
        dataProvider,
        history,
        account
      })}
    >
      <Admin
        title=""
        authProvider={authProvider}
        dataProvider={dataProvider}
        history={history}
        layout={Layout}
        disableTelemetry
        theme={theme}
        customRoutes={customRoutes}
        customReducers={{statesAdded: statesAddedReducer }}
        catchAll={RedirectCalculator}
      >
        {permissions => [
          permissions.includes('Forms.Create')
            ? <Resource name={resources.forms} {...form} />
            : null,
          <Resource name="search/calculations" {...calculationHistory} />,
          permissions.includes('Forms.Update') 
            ? <Resource name="search/users" {...userManagement} />
            : null
          ,
          <Resource name="search/forms/projections/formNumbers"
                    {...search_forms_projections_formNumbers} />
        ]}
      </Admin>
      <Footer />
    </Provider>
  );
}

export default App;
