import { Button, Dialog, DialogTitle, Grid, Table, TableBody } from '@material-ui/core';
import React, { useRef } from 'react';
import { CardContentInner, Toolbar } from 'react-admin';
import LabeledOutline from '../common/LabeledOutline';
import { withStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import PrintIcon from '@material-ui/icons/Print';
import { useReactToPrint } from 'react-to-print';

import SanitizedBox from './common/SanitizedBox';
import TextTableRow from './common/TextTableRow';
import CalcResultsTableRow from './common/CalcResultsTableRow';
import PercentTableRow from './common/percentTableRow';

const styles = theme => ({
  grid: { minWidth: '340px' },
  calculateButtonGrid: { minWidth: '150px' },
  tableCell: { border: 'none' },
  tableCellTotal: { borderBottom: 'none', borderTop: 'solid 1px' },
});

class PrintCalculationBody extends React.PureComponent {
  render() {
    const { values, classes } = this.props;

    return (
      <CardContentInner>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            User:
          </Grid>
          <Grid item xs={6}>
            {values?.calcUser}
          </Grid>
          <Grid item xs={6}>
            Calculation Date:
          </Grid>
          <Grid item xs={6}>
            {values?.calcResults?.calcDate ? values?.calcResults?.calcDate.substring(0, 10) : ''}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LabeledOutline label='Inputs'>
              <Table size='small'>
                <TableBody>
                  <TextTableRow label='Form Number:' value={values?.formNum} />
                  <TextTableRow label='State Purchased:' value={values?.purchaseState} />
                  <TextTableRow label='Finance Company:' value={values?.financeCo} />
                  <TextTableRow label='Account Number:' value={values?.accountNum} />
                  <CalcResultsTableRow label='GAP Waiver Purchase Price:' value={values?.purchaseAmt} />
                  <TextTableRow label='Date Purchased:' value={values?.purchaseDate} />
                  <TextTableRow label='Length of RISC (months):' value={values?.contractMonths} />
                  <PercentTableRow label='Interest Rate:' value={values?.interestRate} />
                  <TextTableRow label='Date Cancelled or Termination of RISC:' value={values?.contractEndDate} />
                </TableBody>
              </Table>
            </LabeledOutline>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LabeledOutline label='Results'>
              <Table size='small'>
                <TableBody>
                  <CalcResultsTableRow label='Refund Amount:' value={values?.calcResults?.baseRefundAmt} />
                  <CalcResultsTableRow label='Cancellation Fee:' value={values?.calcResults?.cancelFeeAmt} />
                  <CalcResultsTableRow label='Interest Refund Amount:'
                                      value={values?.calcResults?.interestRefundAmt} />
                  <CalcResultsTableRow label='Total Refund Due:' value={values?.calcResults?.totalRefundAmt}
                                      className={classes?.tableCellTotal} />
                </TableBody>
              </Table>
            </LabeledOutline>
          </Grid>
        </Grid>
      </CardContentInner>
    );
  }
  
}

const PrintCalculation = (props) => {
  const {values, handleClose, handlePrint, classes, open } = props;
  const ref = useRef();

  const reactPrintFunction = useReactToPrint({
    content: () => ref.current,
  });

  const doPrint = () => {
    reactPrintFunction();
    handlePrint();
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={false}>
      <DialogTitle onClose={handleClose}>
        GAP Waiver Refund Calculation
      </DialogTitle>
      <PrintCalculationBody values={values} classes={classes} ref={ref} />
      <Toolbar>
        <SanitizedBox component='span' mr={2} mb={1.5}>
          <Button
            variant='contained'
            color='primary'
            label='Cancel'
            startIcon={<CancelIcon />}
            onClick={handleClose}>
            Cancel
          </Button>
        </SanitizedBox>
        <SanitizedBox component='span' mr={2} mb={1.5}>
          <Button
            variant='contained'
            color='primary'
            label='Print'
            startIcon={<PrintIcon />}
            onClick={doPrint}
          >
            Print
          </Button>
        </SanitizedBox>
      </Toolbar>
    </Dialog>
  );
}

export default withStyles(styles)(PrintCalculation);
