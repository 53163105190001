import React from "react";
import { Typography, Button, Card, CardHeader, Grid } from '@material-ui/core';
import { colors } from '../../app/theme';
import { useSelector } from 'react-redux';
import PersonIcon from '@material-ui/icons/Person';
import styled from 'styled-components';
import authentication from '../../auth/b2c';

const Icon = styled(PersonIcon)`
  color: ${colors.grey};
  font-size: x-large;
`
const TitleText = styled(Typography)`
  font-size: .75em;
  color: ${colors.grey};
  float: right;
  padding-left: 1em;
`
const NameText = styled(Typography)`
  color: ${colors.blue};
`
const BodyTextContainer = styled(Grid)`
  padding: .5em 2em;
`
const UserProfile = () => {
  const user = useSelector(state => state.user);

  return (
    <>
      <Card>
        <Grid container spacing={2} style={{marginBottom: 10}}>
          <CardHeader variant='contained' title={
            <>
              <Icon />
              <TitleText>Profile</TitleText>
            </>
          }>
          </CardHeader>
          <Grid item xs={12}>
            <NameText variant='h4'>{user.idTokenClaims.name}</NameText>
          </Grid>
          <BodyTextContainer item xs={12}>
            <strong>Username: </strong> {user.idTokenClaims.email}
          </BodyTextContainer>
          <BodyTextContainer item xs={12}>
            <strong>User role:</strong> {user.idTokenClaims.group}
          </BodyTextContainer>
        </Grid>
        <Grid item xs={2}>
          <Button variant="contained" color="primary" size="small"
            onClick={authentication.changePassword}>
            Change Password
          </Button>
        </Grid>
      </Card>
    </>
  );
};

export default UserProfile;
