import formIcon from './formIcon';
import FormList from './formList';

export const form = {
  list: FormList,
  icon: formIcon
};

export const search_forms_projections_formNumbers = {
  list: false
}
