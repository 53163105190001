import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import authentication from '../auth/b2c';
import { apiConfig } from '../auth/apiConfig';
import resources from './resources';

const httpClient = async (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = await authentication.getAccessToken();
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const jsonStringify = (obj) => JSON.stringify(obj, (key, value) => {
  if (value !== null) return value
})

const apiUrl = apiConfig.apiUrl;

const DataProvider = {
  getList: async (resource, params) => {
    let baseUrl = apiUrl;
    const filter = jsonStringify(params?.filter)
    if (resource === resources.forms && filter === "{}") {
      return Promise.resolve({
        data: [],
        total: null
      })
    }

    const query = {
      Filter: filter,
      Page: params?.pagination?.page,
      RowsPerPage: params?.pagination?.perPage,
      Sort: params?.sort?.field,
      Order: params?.sort?.order,
    };

    const url = `${baseUrl}/${resource}?${stringify(query)}`;

    try {
      const { json } = await httpClient(url);
    
      if (json.data === null) {
        return {
          data: [],
          total: 0
        };
      }
      return {
        data: json.data,
        total: json.itemCount
      };
    } catch(e) {
      return {total: 0, data: e};
    }
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  getMany: (resource, params) => {
    const url = `${apiUrl}/${resource}`;
    return httpClient(url).then(({ headers, json }) => {
      return {
        data: json.data,
        total: json.itemCount
      }
    });
  },

  update: (resource, params) => {
    if (resource === resources.user_management) {
      if (params.actionType === 'set_active_status') {
        return httpClient(`${apiUrl}/users/${params.userId}/enable?enabled=${params.value}`, {
          method: 'PATCH',
          body: JSON.stringify(params.value),
        }).then(({ json }) => ({ data: json }));
      }
    }

    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  updateActive: (resource, params) => {
    if (resource === resources.forms) {
      return httpClient(`${apiUrl}/forms/${params.id}?enabled=${params.data.active}`, {
        method: 'PATCH',
        body: JSON.stringify(params),
      }).then(({ json }) => ({ data: json }))
      .catch(error => Promise.reject(error));
    } else {
      const newRecord = {
        value: params.value,
        ...params.value
      }
      return httpClient(`${apiUrl}/users/${params.userId}/enable?enabled=${params.value}`, {
        method: 'PATCH',
        body: JSON.stringify(newRecord),
      }).then(({ json }) => ({ data: params.value }))
      .catch(error => Promise.reject(error));
    }
  },

  updateFinanceCos: (params) => {
    console.log(params.value.newValue);
    return httpClient(`${apiUrl}/users/${params.userId}/financeCo/${params.value.financeCo}/enable?enabled=${params.value.enabled}`, {
      method: 'PATCH',
      body: JSON.stringify({}),
    }).then(({ json }) => ({ data: json }))
    .catch(error => Promise.reject(error));
  },

  create: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => {
      return ({
        data: json[0],
      });
    });
  },

  validate: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: json,
    })),

  validateAsync: async (resource, params) =>
  {
    const { json } = await httpClient(`${apiUrl}/${resource}`,
      {
        method: 'POST',
        body: JSON.stringify(params.data)
      } );

    return {data: json };

  },

  calculate: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: json,
    })),
};

export default DataProvider;
