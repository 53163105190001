// TODO: Build out the footer to display TBD items (language, links, support email)
const Footer = () => {
  return (
  <div style={{
      position: 'fixed', right: 0, bottom: 0, left: 0, zIndex: 100,
      padding: 6,
      backgroundColor: '#efefef',
      color: '#AAAAAA',
      textAlign: 'center',
      'fontfamily': '"Roboto", "Helvetica", "Arial", sans-serif',
    }}>
      &copy; {new Date().getFullYear()} Finance and Insurance Refund Recovery Co. (FAIRR)      
    </div>
  )
};

export default Footer;
