import { createMuiTheme } from '@material-ui/core/styles';

export const colors = {
  darkBlue: '#0355bb',
  blue: '#4280cc',
  lightBlue: '#81aadd',
  fadedBlue: '#c0d4ee',
  black: '#000',
  darkGrey: '#333333',
  grey: '#cccccc',
  lightGrey: '#f0f0f0',
  white: '#fff',
  red: '#e3022f',
};

let theme = createMuiTheme({});

theme = {
  overrides: {
    button: {
      display: 'none'
    },
    RaLayout: {
      content: {
        [theme.breakpoints.up("xs")]: {
          paddingTop: '2em',
          paddingLeft: '1em'
        },
      }
    },
    RaToolbar: {
      toolbar: {
        backgroundColor: colors.grey
      }
    },
    RaAppBar: {
      toolbar: {
        color: colors.black,
        backgroundColor: colors.grey
      }
    },
    RaSidebar: {
      drawerPaper: {
        [theme.breakpoints.up("xs")]: {
          backgroundColor: colors.darkBlue
        }
      }
    },
    RaLogout: {
      icon: {
        color: colors.white
      },
      menuItem: {
        fontWeight: 'bold',
        color: colors.white
      }
    },
    RaMenuItemLink: {
      icon: {
        color: colors.white
      },
      active: {
        color: colors.grey,
        backgroundColor: "rgba(0, 0, 0, 0.2)"
      },
      root: {
        fontWeight: 'bold',
        color: colors.white
      }
    },
    MuiMenu: {
      list: {
        backgroundColor: colors.darkBlue,
        color: colors.white
      }
    },
    MuiListItem: {
      button: {
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.1)"
        }
      }
    },
    MuiCard: {
      root: {
        padding: '1em',
      }
    }
  },
  palette: {
    primary: {
      main: colors.darkBlue,
    },
    secondary: {
      main: colors.lightGrey,
    },
    error: {
      main: colors.red,
    },
    text: {
      disabled: colors.grey,
      primary: colors.darkGrey,
      secondary: colors.blue
    },
    background: {
      default: colors.white,
    },
  },
};

export default theme;
