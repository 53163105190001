import * as msal from "@azure/msal-browser";
import b2cPolicies from './b2cPolicies';
import { msalConfig, loginRequest, tokenRequest } from './authConfig';
import callApi from './api'

const state = {
  launchApp: null,
  account: null,
  accessToken: null
}

const mSALObj = new msal.PublicClientApplication(msalConfig)

mSALObj.handleRedirectPromise()
  .then(handleResponse)
  .catch(error => {
    // Check for forgot password error
    // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
    if (error.errorMessage.indexOf("AADB2C90118") > -1) {
      try {
        console.log("forgotpassword", b2cPolicies.authorities.forgotPassword);
        mSALObj.loginRedirect(b2cPolicies.authorities.forgotPassword);
        return;
      } catch (err) {
        console.log(err);
      }
    }
    console.log("loginRedirect", loginRequest);
    mSALObj.loginRedirect(loginRequest);
  });

function selectAccount() {

  /**
   * See here for more information on account retrieval:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */

  const currentAccounts = mSALObj.getAllAccounts()

  if (currentAccounts.length === 0) {
    state.account = null;
    mSALObj.loginRedirect(loginRequest)
  }
  else if (currentAccounts.length > 1) {
    // Add your account choosing logic here
    console.log("Multiple accounts detected.");
    window.alert("Profile has been updated successfully. \nPlease sign in again.");
    mSALObj.logout()
  }
  else if (currentAccounts.length === 1) {
    state.account = currentAccounts[0]
    if (state.launchApp) {
      state.launchApp(state.account)
    }
  }
}

function handleResponse(response) {
  /**
   * To see the full list of response object properties, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#response
   */
  if (response) {
    // if response contains an access token, store it
    if (response.accessToken && response.accessToken !== "") {
      state.accessToken = response.accessToken
    }

    // for handling B2C user-flows and policies
    handlePolicyChange(response);
  }

  selectAccount();
}

function handlePolicyChange(response) {
  /**
   * We need to reject id tokens that were not issued with the default sign-in policy.
   * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
   * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
   */

  if (response.idTokenClaims['acr'] === b2cPolicies.names.editProfile) {
    window.alert("Profile has been updated successfully. \nPlease sign-in again.");
    mSALObj.logout();
  } else
    if (response.idTokenClaims['acr'] === b2cPolicies.names.forgotPassword) {
      window.alert("Password has been reset successfully. \nPlease sign-in with your new password.");
      mSALObj.logout();
    }
}

function getTokenRedirect(request) {

  /**
  * See here for more info on account retrieval:
  * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
  */

  request.account = mSALObj.getAccountByHomeId(state.account.homeAccountId);

  return mSALObj.acquireTokenSilent(request)
    .then((response) => {
      console.log("getTokenRedirect", response)
      // In case the response from B2C server has an empty accessToken field
      // throw an error to initiate token acquisition
      if (!response.accessToken || response.accessToken === "") {
        throw new msal.InteractionRequiredAuthError();
      }
      return handleResponse(response);
    })
    .catch(error => {
      console.log(error);
      console.log("silent token acquisition fails. acquiring token using popup");
      if (error instanceof msal.InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return mSALObj.acquireTokenRedirect(request);
      }
      else {
        console.log(error);
      }
    });
}

var authentication = {

  run: (launchApp) => {
    state.launchApp = launchApp
  },

  signOut: function signOut() {
    /**
     * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
     */

    // Choose which account to logout from by passing a homeAccountId.
    const logoutRequest = {
      account: mSALObj.getAccountByHomeId(state.account.homeAccountId)
    };

    mSALObj.logout(logoutRequest);
  },

  // Acquires and access token and then passes it to the API call
  callApi: (endpoint) => {
    if (!state.accessToken) {
      getTokenRedirect(tokenRequest);
    }
    else {
      try {
        callApi(endpoint, state.accessToken);
      }
      catch (error) {
        console.log(error);
      }
    }
  },

  // Acquires access token
  getAccessToken: async () => {
    if (!state.accessToken) {
      await getTokenRedirect(tokenRequest)
    }
    return state.accessToken;
  },

  getAccount: function getAccount() {
    return state.account;
  },

  changePassword: function changePassword() {
    mSALObj.loginRedirect(b2cPolicies.authorities.forgotPassword);
  }
};

export default authentication;
