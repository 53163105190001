import { useRef, useState } from 'react';
import { AutocompleteInput, Labeled, LinearProgress, useQuery } from 'react-admin';
import { useFormState } from 'react-final-form';
import _ from 'lodash';
import resources from '../../dataProvider/resources';

const AutocompleteFormNumInput = props => {
  const { financeCo, label, purchaseState, suggestionLimit, validate } = props;
  const { values } = useFormState();
  const [search_forms_projections_formNumbers, setSearch_forms_projections_formNumbers] = useState();
  const filterRef = useRef(null);
  let filter = {};

  if (search_forms_projections_formNumbers === undefined)
    setSearch_forms_projections_formNumbers([{}]);

  if (financeCo && purchaseState) {
    filter = {
      FinanceCos: [financeCo],
      State: purchaseState,
      Active: true,
    };
  }

  if (!_.isEqual(filter, filterRef.current)) {
    values.formNum = '';
    filterRef.current = filter;
  }

  let { data, loading } = useQuery({
    type: 'getList',
    resource: resources.search_forms_projections_formNumbers,
    payload: { filter: filterRef.current },
  });

  const selectedForm = data?.find(o => o.id === values[resources.search_forms_projections_formNumbers]);
  if (selectedForm) {
    values.formNum = selectedForm.formNum;
  }
  values.interestRateRequired = (selectedForm?.duringFrp.finChargeRefund === true || selectedForm?.afterFrp.finChargeRefund === true);

  if (loading) {
    return (
      <Labeled
        label={label}
      >
        <LinearProgress />
      </Labeled>
    );
  } else {
    return (
      <AutocompleteInput
        choices={data}
        label={label}
        optionText="formNum"
        source={resources.search_forms_projections_formNumbers}
        suggestionLimit={suggestionLimit}
        validate={validate}
        variant="outlined"
        InputLabelProps={{
          shrink: true
        }}
        fullWidth
      />
    );
  }
};

export default AutocompleteFormNumInput;
