import React, { Fragment, useState } from 'react';
import { List, Datagrid, TextField, NumberField, DateField, FunctionField, Button } from 'react-admin';
import Filter from './filter';
import Pagination from '../common/Pagination'
import { Form } from 'react-final-form';
import PrintIcon from '@material-ui/icons/Print';
import PrintCalculation from '../calculator/printCalculation';
import dateFormat from 'dateformat';
import { makeStyles } from '@material-ui/core/styles';

const styles = {
  nowrapHeader: {
    whiteSpace: 'nowrap',
    textAlign: 'left'
  },
  // The label prop for a column of buttons is also used as the headers.
  // Since we don't want to show both, we hide it with css.
  hiddenHeader: {
    display: 'none',
    textAlign: 'left'
  },
  tableHeader: {
    textAlign: 'left'
  }
}

const useStyles = makeStyles(styles);

const PrintButton = (props) => {
  const { printButtonClick, record, ...rest } = props;
  const handleClick = () => {
    printButtonClick(record);
  }
  return <Button onClick={handleClick} {...rest} />
}

const CalculationHistoryList = props => {
  const [recordToPrint, setRecordToPrint] = useState(null);
  const classes = useStyles();

  const handlePrint = () => {
    setRecordToPrint(null);
  }

  const handleClose = () => {
    setRecordToPrint(null);
  };

  const printButtonClick = (record) => {
    const contractEndDate = new Date(record.contractEndDate);
    const purchaseDate = new Date(record.purchaseDate);
    setRecordToPrint({
      ...record,
      ...{
        calcResults: {
          interestRefundAmt: record.interestRefundAmt,
          cancelFeeAmt: record.cancelFeeAmt,
          baseRefundAmt: record.baseRefundAmt,
          totalRefundAmt: record.totalRefundAmt,
          calcDate: record.calcDate
        },
        contractEndDate: dateFormat(contractEndDate, 'yyyy-mm-dd'),
        purchaseDate: dateFormat(purchaseDate, 'yyyy-mm-dd'),
      }
    });
  }

  return (
    <Fragment>
      <List filters={<Filter />} pagination={<Pagination />} actions={false} bulkActionButtons={false} {...props}>
        <Datagrid>
          <DateField source="calcDate" label="Calc. Date" headerClassName={classes?.tableHeader} />
          <TextField source="formNum" label="Form #" headerClassName={classes?.nowrapHeader} />
          <TextField source="accountNum" label="Account #" headerClassName={classes?.nowrapHeader} />
          <NumberField source="purchaseAmt" label="Purchase Price" headerClassName={classes?.tableHeader} options={{style: 'currency', currency: 'USD'}} />
          <TextField source="purchaseState" label="State Purchased" headerClassName={classes?.tableHeader} />
          <TextField source="contractMonths" label="RISC Length" headerClassName={classes?.tableHeader} />
          <FunctionField source="interestRate" label="Interest Rate" headerClassName={classes?.tableHeader} render={record => `${record.interestRate}%`} />
          <DateField source="contractEndDate" label="Cancelled Date" headerClassName={classes?.tableHeader} />
          <NumberField source="baseRefundAmt" label="Refund Amount" headerClassName={classes?.tableHeader} options={{style: 'currency', currency: 'USD'}} />
          <NumberField source="cancelFeeAmt" label="Cancel Fee" headerClassName={classes?.tableHeader} options={{style: 'currency', currency: 'USD'}} />
          <NumberField source="interestRefundAmt" label="Interest Refund" headerClassName={classes?.tableHeader} options={{style: 'currency', currency: 'USD'}} />
          <NumberField source="totalRefundAmt" label="Total Refund" headerClassName={classes?.tableHeader} options={{style: 'currency', currency: 'USD'}} />
          <TextField source="calcUser" label="User" headerClassName={classes?.tableHeader} />
          <TextField source="financeCo" label="Finance Co." headerClassName={classes?.tableHeader} />
          <PrintButton
            variant='outlined'
            color='primary'
            startIcon={<PrintIcon />}
            label='Print'
            printButtonClick={printButtonClick} 
            headerClassName={classes?.hiddenHeader} />
        </Datagrid>
      </List>
      <PrintCalculation handlePrint={handlePrint} handleClose={handleClose} values={recordToPrint} open={recordToPrint !== null} />
    </Fragment>
  )
};

const CalculationHistoryForm = (props) => {
  const onSubmit = (e) => {

  }

  return (
    <Form onSubmit={onSubmit} render={props => <CalculationHistoryList {...props} />} {...props} />
  );
}

export default CalculationHistoryForm;
