const names = {
    signIn: "B2C_1A_SignIn",
    forgotPassword: "B2C_1A_PasswordReset",
}

module.exports = {
    names: names,

    authorities: {
        signIn: {
            authority: `https://${process.env.REACT_APP_B2C_TENANT_NAME}.b2clogin.com/${process.env.REACT_APP_B2C_TENANT_NAME}.onmicrosoft.com/${names.signIn}`
        },
        forgotPassword: {
            authority: `https://${process.env.REACT_APP_B2C_TENANT_NAME}.b2clogin.com/${process.env.REACT_APP_B2C_TENANT_NAME}.onmicrosoft.com/${names.forgotPassword}`
        }
    },
    authorityDomain: `${process.env.REACT_APP_B2C_TENANT_NAME}.b2clogin.com`
}
