import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import authentication from './auth/b2c';

authentication.run((account) => {
  ReactDOM.render(
    <App account={account} />,
    document.getElementById('root')
  );
});