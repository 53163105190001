import React from 'react';
import {
  Create
} from 'react-admin';
import FormManagementForm from './formManagementForm'

export const styles = {
  label: { fontSize: 12, textAlign: 'left', maxWidth: '80%' },
  input: { minWidth: '0', width: '100%' },
};

const FormCreate = props => {

  return (
    <Create {...props}>
      <FormManagementForm {...props} />
    </Create>
  )
}

export default FormCreate
