const Resources = {
  calculator: "calculator",
  financeCos: "financeCos",
  forms: "forms",
  search_forms_projections_formNumbers: "search/forms/projections/formNumbers",
  calculationHistory: "search/calculations",
  user_management: "search/users"
};

export default Resources;
