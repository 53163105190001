import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Profile from '../components/userProfile';
import Calculator from '../components/calculator';

const customRoutes = [
  <Route path="/Profile"  render={() => <Profile />} />,
  <Route path={Calculator.route}  render={props => <Calculator.Form {...props} />} />,
  <Route exact path="/">
    <Redirect to="/calculator" />
  </Route>
];

export default customRoutes;
