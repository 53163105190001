import React, { Fragment, useState, useEffect } from 'react';
import { useDataProvider, CardContentInner } from 'react-admin';
import { 
    Button, 
    Dialog, 
    DialogTitle, 
    Box, 
    Table, 
    TableBody, 
    TableHead, 
    TableRow, 
    TableCell, 
    TableSortLabel, 
    Checkbox, 
    MenuItem, 
    ListItemText, 
    FormControl, 
    Select,
    TablePagination,
    Toolbar,
    TextField,
    Snackbar,
    Card,
    InputLabel
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import resources from '../../dataProvider/resources';
import sortObjectsArray from 'sort-objects-array';
import compare from 'string-compare';
import { ExportToCsv } from 'export-to-csv';
import { makeStyles } from '@material-ui/core/styles';

const styles = {
    tableRow: {
        "&:hover": {
            backgroundColor: 'rgba(0, 0, 0, 0.04) !important'
        }
    }
}

const useStyles = makeStyles(styles);

const exportOptions = {
    fieldSeperator: ',',
    quoteStrings: '"',
    showTitle: false,
    title: 'FAIRR_User_Export',
    useKeysAsHeaders: true,
    filename: 'FAIRR_User_Export'
};

const csvExporter = new ExportToCsv(exportOptions);

const filter = (list, filterBy, filterValue) => {
    if (filterBy === null || filterValue === null) return list;
    return list.filter(item => {
        return compare(item[filterBy], filterValue) > .5;
    }).sort((a, b) => compare(a[filterBy], filterValue) > compare(b[filterBy], filterValue));
}

const filterFinanceCo = (list, filterValue) => {
    if (filterValue === null) return list;
    return list.filter(item => {
        return item.financeCos.indexOf(filterValue) !== -1;
    });
}

const StyledTableSortLabel = (props) => (
    <TableSortLabel style={{color: '#000'}} {...props} />
);

const UserManagement = props => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    
    const [userEdit, setUserEdit] = useState({
        isEditing: false,
        value: null,
        userId: null,
        actionType: null
    });

    const [tableData, setTableData] = useState([]);
    const [isUserFetchError, setIsUserFetchError] = useState(false);
    const [isFinanceCoFetchError, setIsFinanceCoFetchError] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [userFinanceCos, setUserFinanceCos] = useState([]);
    const [financeCos, setFinanceCos] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('displayName');
    const [nameFilter, setNameFilter] = useState("");
    const [emailFilter, setEmailFilter] = useState("");
    const [financeCoFilter, setFinanceCoFilter] = useState("");

    const handleSort = (column) => {
        if (orderBy !== column) {
            setOrder('asc');
            setOrderBy(column);
        } else if (order === 'asc') {
            setOrder('desc');
        } else {
            setOrder('asc');
        }
    }
    
    const handleNameSort = () => {
        handleSort('displayName');
    }
    
    const handleEmailSort = () => {
        handleSort('mail');
    }
    
    const handleRoleSort = () => {
        handleSort('role');
    }

    const handlePageChange = (e, newPage) => {
        setPage(newPage);
    }

    const handleRowsPerPageChange = (e) => {
        setRowsPerPage(e.target.value);
    }

    const financeCoChange = (e, source, userId) => {
        const value = source.props.value;
        const previousValue = tableData.find(item => item.id === userId).financeCos
        const enabled = (e.target.value?.length > previousValue.length) ? true : false;

        setUserEdit({
            isEditing: true,
            value: {enabled: enabled, financeCo: value, newValue: e.target.value},
            userId,
            actionType: 'finance_co'
        });
    }

    const handleSave = () => {
        if (userEdit.actionType === 'set_active_status') {
            dataProvider.updateActive('search/users', {
                actionType: userEdit.actionType,
                value: userEdit.value,
                userId: userEdit.userId
            }).then(() => {
                const tableItem = tableData.find(row => row.id === userEdit.userId);
                tableItem.accountEnabled = userEdit.value;
                tableData[tableData.findIndex(row => row.id === userEdit.userId)] = tableItem;
                setTableData(prevData => [...tableData]);
                const filteredItem = filteredData.find(row => row.id === userEdit.userId);
                filteredItem.accountEnabled = userEdit.value;
                filteredData[filteredData.findIndex(row => row.id === userEdit.userId)] = filteredItem;
                setFilteredData(prevData => [...filteredData]);
            });
        } else if (userEdit.actionType === 'finance_co') {
            dataProvider.updateFinanceCos(userEdit).then(() => {
                const newTableData = tableData.slice();
                newTableData.find(user => user.id === userEdit.userId).financeCos = userEdit.value.newValue;
                setTableData(prevData => [...newTableData]);
                const newData = userFinanceCos.slice();
                newData.find(user => user.userId === userEdit.userId)[userEdit.value.financeCo] = userEdit.value.enabled;
                setUserFinanceCos(prevData => [...newData]);
            });
        } else {
            dataProvider.update('search/users', {
                actionType: userEdit.actionType,
                value: userEdit.value,
                userId: userEdit.userId
            });
        }

        closeDialog();
    }

    const closeDialog = () => {
        setUserEdit({
            isEditing: false,
            value: null,
            userId: null,
            actionType: null
        });
    }

    const activeChange = (e, value, userId) => {
        setUserEdit({
            isEditing: true,
            value,
            userId,
            actionType: 'set_active_status'
        });
    }

    useEffect(() => {
        async function initialize() {
            const usersResp = await dataProvider.getList(resources.user_management, {filter: {}, pagination: {page: 1, rowsPerPage: 10}}).catch(error => setIsUserFetchError(true));
            const financeCosResp = await dataProvider.getList(resources.financeCos).catch(error => setIsFinanceCoFetchError(true));
            
            setTableData(usersResp.data);
            setFilteredData(usersResp.data);
            setFinanceCos(financeCosResp.data);
            setUserFinanceCos(usersResp.data.map(user => {
                const obj = {
                    userId: user.id
                };
                financeCosResp.data?.forEach(financeCo => {
                    obj[financeCo.id] = user.financeCos.indexOf(financeCo.id) === -1 ? false : true;
                });
                return obj;
            }));
        }
        initialize();
    }, []);

    const handleNameChange = (e) => {
        setNameFilter(e.target.value);
    }

    const handleEmailChange = (e) => {
        setEmailFilter(e.target.value);
    }

    const financeCoFilterChange = (e) => {
        setFinanceCoFilter(e.target.value);
    }

    const handleSearch = (e) => {
        if (nameFilter !== null && nameFilter !== '') {
            setOrder('asc');
            setOrderBy(null);
            setFilteredData(filter(tableData, 'displayName', nameFilter));
        } else if (emailFilter !== null && emailFilter !== '') {
            setOrder('asc');
            setOrderBy(null);
            setFilteredData(filter(tableData, 'mail', emailFilter));
        } else if (financeCoFilter !== null && financeCoFilter !== '') {
            setOrder('asc');
            setOrderBy(null);
            setFilteredData(filterFinanceCo(tableData, financeCoFilter));
        }
    }

    const handleClearSearch = () => {
        setNameFilter("");
        setEmailFilter("");
        setFinanceCoFilter("");
        setFilteredData(tableData);
    }

    const handleExport = () => {
        const exportData = sortObjectsArray(filteredData, orderBy, order)
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(row => {
                const { financeCos, ...rest } = row;
                return {
                    financeCos: financeCos.join(','),
                    ...rest
                };
            }
        );
        csvExporter.generateCsv(exportData);
    }

    const allFinanceCos = financeCos.map(t => t.id);

    return (
        <Fragment>
            <Toolbar style={{marginBottom: 20, padding: 0}}>
                <Box style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <Box style={{padding: 20, border: '1px solid rgb(66, 128, 204)', borderRadius: 8}}>
                        <TextField onChange={handleNameChange} value={nameFilter} id="name-filter" label="Name" variant="outlined" size="small" style={{marginRight: 10, borderColor: 'rgb(66, 128, 204)'}} />
                        <TextField onChange={handleEmailChange} value={emailFilter} id="email-filter" variant="outlined" size="small" label="Email" style={{marginRight: 10, borderColor: 'rgb(66, 128, 204)'}} />
                        <FormControl style={{marginRight: 10, width: 250}}>
                            <InputLabel margin="dense" variant="outlined">Finance Companies</InputLabel>
                            <Select
                                value={financeCoFilter}
                                onChange={financeCoFilterChange}
                                variant="outlined"
                                margin="dense"
                            >
                                <MenuItem value=""></MenuItem>
                                {financeCos?.map(financeCo => <MenuItem value={financeCo.id} key={financeCo.id}>{financeCo.financeCompany}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <Button onClick={handleSearch} variant="contained" color="primary" style={{marginRight: 10}}>Search</Button>
                        <Button onClick={handleClearSearch} variant="contained" color="primary">Clear Search</Button>
                    </Box>
                    <Box style={{padding: 20}}>
                        <Button onClick={handleExport} color="primary" startIcon={<GetAppIcon />}>Export</Button>
                    </Box>
                </Box>
            </Toolbar>
            <Card>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <StyledTableSortLabel active={orderBy === 'displayName'} direction={order} onClick={handleNameSort}>Name</StyledTableSortLabel>
                            </TableCell>
                            <TableCell>
                                <StyledTableSortLabel active={orderBy === 'mail'} direction={order} onClick={handleEmailSort}>Email</StyledTableSortLabel>
                            </TableCell>
                            <TableCell width={60}>
                                <StyledTableSortLabel active={orderBy === 'role'} direction={order} onClick={handleRoleSort}>Role</StyledTableSortLabel>
                            </TableCell>
                            <TableCell>
                                Finance Companies
                            </TableCell>
                            <TableCell width={20}>
                                Active
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {sortObjectsArray(filteredData, orderBy, order)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                        <TableRow key={row.id} className={classes.tableRow} style={{backgroundColor: '#fff'}}>
                            <TableCell>{row.displayName}</TableCell>
                            <TableCell>{row.mail}</TableCell>
                            <TableCell>{row.role}</TableCell>
                            <TableCell>
                            <FormControl style={{width: '100%'}}>
                                <Select 
                                    multiple 
                                    value={row.role === 'Admin' ? allFinanceCos : row.financeCos} 
                                    onChange={(e, value) => financeCoChange(e, value, row.id)} 
                                    renderValue={selected => selected?.join(', ')}
                                    disabled={row.role === 'Admin'}
                                >
                                    {financeCos?.map(financeCo => {
                                        const userFinanceCo = userFinanceCos?.find(userFinanceCo => userFinanceCo.userId === row.id);
                                        let selected;
                                        if (row.role === 'Admin')
                                            selected = true;
                                        else
                                            selected = userFinanceCo ? userFinanceCo[financeCo.id] : false;

                                        return (
                                            <MenuItem variant="outlined" value={financeCo.id} key={financeCo.id}>
                                                <Checkbox checked={selected} />
                                                <ListItemText primary={financeCo.financeCompany} />
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                </FormControl>
                            </TableCell>
                            <TableCell><Checkbox checked={row.accountEnabled} onChange={(e, value) => activeChange(e, value, row.id)} /></TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                <Box style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <TablePagination 
                        rowsPerPageOptions={[5, 10, 25]} 
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handleRowsPerPageChange}
                        count={filteredData.length}
                    />
                </Box>
            </Card>
            
            <Dialog open={userEdit.isEditing}>
                <DialogTitle>Edit Confirmation</DialogTitle>
                <CardContentInner>
                    <p>
                        {userEdit.actionType === 'finance_co' && userEdit.value.enabled === true && `Are you sure you want to associate the finance company ${userEdit.value.financeCo} to user?`}
                        {userEdit.actionType === 'finance_co' && userEdit.value.enabled === false && `Are you sure you want to dis-associate the finance company ${userEdit.value.financeCo} to user?`}
                        {userEdit.actionType === 'set_active_status' && userEdit.value === true && `Are you sure you want to activate this user?`}
                        {userEdit.actionType === 'set_active_status' && userEdit.value === false && `Are you sure you want to de-activate this user?`}
                    </p>
                    <Toolbar>
                        <Button
                            variant='contained'
                            color='primary'
                            label='Save'
                            startIcon={<SaveIcon />}
                            onClick={handleSave}
                            style={{marginRight: '20px'}}>
                            Save
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            label='Cancel'
                            startIcon={<CancelIcon />}
                            onClick={closeDialog}>
                            Cancel
                        </Button>
                    </Toolbar>
                </CardContentInner>
            </Dialog>

            {isUserFetchError && <Snackbar severity="error" open={isUserFetchError} autoHideDuration={4000} onClose={() => setIsUserFetchError(false)} />}
            {isFinanceCoFetchError && <Snackbar severity="error" open={isFinanceCoFetchError} autoHideDuration={4000} onClose={() => setIsFinanceCoFetchError(false)} />}
        </Fragment>
    )
};

export default UserManagement;