import { CREATE } from 'react-admin';

export const STATES_ADDED = 'STATES_ADDED';
export const statesAddedAction = (formValues, basePath) => ({
  type: STATES_ADDED,
  payload: formValues,
  meta: {
    resource: 'forms',
    fetch: CREATE,
    onSuccess: {
      notification: {
        body: 'STATES ADDED',
        level: 'info',
      },
      redirectTo: `/forms?filter=%7B"FormNum"%3A"${formValues.data.formNum}"%2C"FinanceCos"%3A%5B"${formValues.data.financeCo}"%5D%7D&order=ASC&page=1&perPage=10&sort=id`,
      basePath,
    },
    onFailure: {
      notification: {
        body: 'STATES ADD FAILURE',
        level: 'warn',
      },
    },
  },
});
